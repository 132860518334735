import React from "react"
import PropTypes from "prop-types"
import root from 'window-or-global'


export default
  class WistiaVideo extends React.Component {

  componentWillMount() {
    if (!!root.document) {

      const script1 = root.document.createElement("script");
      const script2 = root.document.createElement("script");

      script1.src = "https://fast.wistia.com/embed/medias/e9v61c5qiw.jsonp";
      script1.async = true;

      script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
      script2.async = true;

      root.document.body.appendChild(script1);
      root.document.body.appendChild(script2);
    }
  }

  render() {
    return (
      <section className="container ctr-video">

        <h3>Watch to see how MSM can help your practice navigate the changing world of healthcare.</h3>

        <div className="wistia_responsive_padding " style={{ "padding": "56.25% 0 0 0", "position": "relative" }}> <div className="wistia_responsive_wrapper" style={{ height: "100%", "left": "0", "position": "absolute", "top": "0", "width": "100%" }}
        >

          <div className="wistia_embed wistia_async_e9v61c5qiw videoFoam=true" style={{ position: "absolute" }}>
            <div className="wistia_swatch" style={{ "height": "100%", "left": "0", "opacity": "1", "overflow": "hidden", "position": "absolute", "top": "0", "transition": "opacity 200ms", "width": "100%" }}><img src="https://fast.wistia.com/embed/medias/e9v61c5qiw/swatch" alt="" aria-hidden="true" /></div></div>
        </div>

        </div>
      </section>);
  };
}