import React from "react"
import Fade from 'react-reveal/Fade'
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import "../styles/index.scss"
import * as Scroll from 'react-scroll';

import Layout from "../components/layout"
import WistiaVideo from "../components/video"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

import PremiumCareMobile from "../images/editorial/header-image-premium-care-mobile.jpg"
import PremiumCare from "../images/editorial/header-image-premium-care.jpg"

import IconRevCycle from "../images/svg/icon-rev-cycle.svg"
import IconContracting from "../images/svg/icon-contracting-credentialing.svg"
import IconConsulting from "../images/svg/icon-mgmt-services-consulting.svg"
import IconPracticeMgmt from "../images/svg/icon-practice-mgmt.svg"

import ImgGreatestPotential from "../images/svg/isometric-greatest-potential.svg"
import ImgWhyMSM from "../images/svg/isometric-why-msm.svg"

const ScrollLink = Scroll.Link;
const Element = Scroll.Element;

const IndexPage = () => (

  <React.Fragment>
    <SEO title="Home" description="With Medical Specialties Managers, Inc. (MSM), the future of medical group management is personalized, high-touch, and one hundred percent transparent" />

    <Layout styleClass="home">

      <section id="sct-uvp" className="container ctr-headline">
        <div className="frame-image">

          <article>
            <Fade bottom delay={800}>
              <h2>Your practice deserves <br /> <span>premium care, too. </span></h2>
            </Fade>
            <Fade bottom delay={1500}>
              <button className="btn-primary">
                <ScrollLink spy={false} hashSpy={false} duration={1000} offset={-80} smooth={true} to="features" >
                  Discover How</ScrollLink>
              </button>
            </Fade>
          </article>



          <picture>
            <source srcSet={PremiumCareMobile} media="(max-width:600px)" />
            <source srcSet={PremiumCare} />
            <img src={PremiumCare} alt="" className="lazyload" />
          </picture>
        </div>
      </section>
      <section id="sct-message" className="container ctr-message">
        <h3>MSM offers high-touch solutions for the next generation of healthcare providers.</h3>
        <p>With Medical Specialties Managers, Inc. (MSM), the future of medical group management is personalized, high-touch, and one hundred percent transparent</p>
      </section>




      <Element name="features">
        <section id="sct-features" className="container ctr-features">
          <Link className="cta" to="/solutions/revenue_cycle_management">
            <article id="art-rcw">
              <div style={{ width: '113px' }}>
                <img src={IconRevCycle} />
              </div>
              <h3>Revenue Cycle Management</h3>
              <p>Put more revenue back into your practice with our full-service turnkey revenue cycle management services. </p>

              Learn more <FontAwesomeIcon icon={faLongArrowAltRight} />
            </article>
          </Link>
          <Link className="cta" to="/solutions/payor_contracting_and_credentialing">
            <article id="art-rcw">
              <div style={{ width: '91px' }}>
                <img src={IconContracting} />
              </div>
              <h3>Payor Contracting & Credentialing</h3>
              <p>Get the right contracts—and the pay you deserve—with our extensive contracting and credentialing expertise. </p>
              Learn more <FontAwesomeIcon icon={faLongArrowAltRight} />
            </article>
          </Link>
          <Link className="cta" to="/solutions/management_services_and_consulting">
            <article id="art-pcc">
              <div style={{ width: '82px' }}>
                <img src={IconConsulting} />
              </div>
              <h3>Management Services & Consulting</h3>
              <p>We work with you to strategize actionable ways for you to develop your practice—from planning to people, we do it all.  </p>
              Learn more <FontAwesomeIcon icon={faLongArrowAltRight} />
            </article>
          </Link>
          <Link className="cta" to="/solutions/practice_management">
            <article id="art-rcw">
              <div style={{ width: '113px' }}>
                <img src={IconPracticeMgmt} />
              </div>
              <h3>Practice Management</h3>
              <p>Let MSM take over everything you need to grow, from accounting to HR, IT to operations, revenue cycle to risk management. </p>
              Learn more <FontAwesomeIcon icon={faLongArrowAltRight} />
            </article >
          </Link>
        </section >
      </Element>


      <WistiaVideo />


      <section className="container ctr-potential animatedParent animateOnce" id="sct-potential">
        <Fade bottom cascade delay={500}>

          <div>
            <h2>Reach your greatest potential.</h2>
            <p>Your practice deserves the same kind of special attention your patients do. But for physician groups, finding a partner with the expertise and flexibility to meet your evolving practice management needs has been notoriously difficult. With a majority of management options targeting larger healthcare systems, physician practices have lacked the individualized attention needed to thrive. </p>

            <p>That’s where MSM comes in. We’re unwavering in our mission to increase revenue, decrease expenses, and act as a strategic partner to promote optimal growth and performance. </p>

          </div>
        </Fade>
        <img src={ImgGreatestPotential} alt="" />

      </section>

      <section id="sct-why-msm" className="container ctr-why animatedParent animateOnce">


        <div className="">
          <Fade bottom delay={1500}>
            <h2 className=''>Why MSM?</h2>
          </Fade>
          <Fade bottom delay={1700}>
            <p >Whether you’re a brand-new practice or an established group in a healthcare system, we have a solution that will help you grow. Let us guide you toward a customizable blend of advanced technology and expert professionals designed to support the needs of your practice perfectly.</p>
          </Fade>
          <Fade bottom delay={1900}>
            <button className="float-nav nav-button btn-primary">
              <Link to="/about">Learn More</Link>
            </button>
          </Fade>
        </div>



        <img src={ImgWhyMSM} alt="" />


      </section>

    </Layout >




  </React.Fragment >

)

export default IndexPage
